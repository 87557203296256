import React from 'react';
import { Box, Heading, Text, SimpleGrid, Image, Button, useToast } from '@chakra-ui/react';
import { handleClick } from './Home';
const WebcamEnVivo = () => {
  const toast = useToast();

  return (
    <Box maxWidth="1200px" margin="auto" padding="20px">
      <Heading as="h1" size="2xl" textAlign="center" mb="6">
        Webcam en Vivo con Chicas Latinas Sexys
      </Heading>
      
      <Text fontSize="xl" mb="8">
        Experimenta la emoción de ver y interactuar con hermosas chicas latinas en tiempo real a través de nuestras webcams en vivo.
      </Text>
      
      <SimpleGrid columns={[1, 2, 3]} spacing="20px" mb="10">
        <Image src="/girl1.png" alt="Chica en webcam" borderRadius="md" />
        <Image src="/girl2.png" alt="Modelo en vivo" borderRadius="md" />
        <Image src="/girl3.png" alt="Transmisión en directo" borderRadius="md" />
      </SimpleGrid>
      
      <Box textAlign="center" mt="10">
        <Button colorScheme="pink" size="lg"
        as="a"
        href="https://t.me/DreamGFXXXBot"
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => handleClick(toast)}>
          Ver Webcams en Vivo
        </Button>
      </Box>
    </Box>
  );
};

export default WebcamEnVivo;