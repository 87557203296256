import React from 'react';
import { Box, Heading, Text, VStack, Button, useToast } from '@chakra-ui/react';
import { handleClick } from './Home';

const CitasVirtuales = () => {
  const toast = useToast();

  return (
    <Box maxWidth="1200px" margin="auto" padding="20px">
      <Heading as="h1" size="2xl" textAlign="center" mb="6">
        Citas Virtuales con Chicas Latinas
      </Heading>
      
      <Text fontSize="xl" mb="8">
        Experimenta la emoción de una cita real desde la comodidad de tu hogar con nuestras citas virtuales.
      </Text>
      
      <VStack spacing="6" align="stretch">
        <Text fontSize="lg">
          Nuestras citas virtuales incluyen:
        </Text>
        <Box>
          <Text fontSize="lg" as="ul" pl="6">
            <li>Videollamadas privadas</li>
            <li>Juegos interactivos</li>
            <li>Experiencias personalizadas</li>
            <li>Opción de regalo virtual</li>
          </Text>
        </Box>
      </VStack>
      
      <Box textAlign="center" mt="10">
        <Button colorScheme="pink" size="lg"
        as="a"
        href="https://t.me/DreamGFXXXBot"
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => handleClick(toast)}>
          Reserva tu Cita Virtual
        </Button>
      </Box>
    </Box>
  );
};

export default CitasVirtuales;