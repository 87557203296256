import React from 'react';
import { Box, Heading, Text, Button, VStack, useToast } from '@chakra-ui/react';
import { handleClick } from './Home';

const ChatEroticoGratis = () => {
  const toast = useToast();

  return (
    <Box maxWidth="1200px" margin="auto" padding="20px">
      <Heading as="h1" size="2xl" textAlign="center" mb="6">
        Chat Erótico Gratis con Chicas Latinas
      </Heading>
      
      <Text fontSize="xl" mb="8">
        Disfruta de conversaciones picantes y sensuales con las chicas más calientes de Latinoamérica, ¡totalmente gratis!
      </Text>
      
      <VStack spacing="6" align="stretch">
        <Text fontSize="lg">
          Nuestro chat erótico gratuito te ofrece:
        </Text>
        <Box>
          <Text fontSize="lg" as="ul" pl="6">
            <li>Acceso instantáneo sin registro</li>
            <li>Chats privados con chicas hermosas</li>
            <li>Opciones de video en vivo</li>
            <li>Variedad de salas temáticas</li>
          </Text>
        </Box>
      </VStack>
      
      <Box textAlign="center" mt="10">
        <Button
          colorScheme="pink"
          size="lg"
          as="a"
        href="https://t.me/DreamGFXXXBot"
        target="_blank"
          rel="noopener noreferrer"
          onClick={() => handleClick(toast)}
        >
          Iniciar Chat Gratis en Telegram
        </Button>
      </Box>
    </Box>
  );
};

export default ChatEroticoGratis;